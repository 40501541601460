import React from 'react';
import { IoEnterOutline } from "react-icons/io5";

import Box from '../../components/Box';
import Flex from '../../components/Flex';
import Text         from '../../components/Text';
import Button from '../../components/Button';
import SeeMore from '../../components/SeeMore';
import Container from '../../components/Container';
import BackgroundImage from '../../components/BackgroundImage';
import { Media, responsive } from '../../contexts/responsive';

import title from './title.svg';
import logo from './logo.svg';
import relab from './relab.svg';
import relab_desk from './relab-desk.svg';
import new_logo from './new-logo.svg';

import Like from './Like';
import Important from './Important';
import Team from './Team';
import Industry from './Industry';

const AboutPage = () => (
  <Box>
    <Container pt={responsive('2.5em', '5.25em')}>
      <Flex flexDirection={responsive('row', 'column-reverse')}>
        <Box flex={1} mr={responsive('1.75em', 0)}>
          <Text.Bold textAlign={responsive('left', 'center')} fontSize={responsive('1.75em', '3.75em')}>團隊故事</Text.Bold>
          <Flex justifyContent={responsive('flex-start', 'center')}>
            <Text mt={responsive('0.625rem', '1em')} fontSize={responsive('0.875em', '1.875em')}>「我們10歲了！」<br />除了持續培養「對資料的品味」，同時強化對市場溝通的敏銳度，現在起，是品嚐 RE:LAB 合作體驗最好的起始年份！</Text>
          </Flex>
          <Box width={responsive('12.125em', '26.75em')} pt={responsive('2.5em', '10em')}>
            <BackgroundImage ratio={427.17 / 92.18} src={title} />
          </Box>
          <Flex flexDirection={responsive('column', 'row')} pt={responsive('2.875em', '4.75em')}>
            <Box pt={responsive(0, '1.875em')} pb={responsive(0, '2.875em')} maxWidth={responsive('33.75em', 'unset')} width={responsive('100%', '50%')} mx="auto">
              <BackgroundImage ratio={540.83 / 204.29} src={logo} />
            </Box>
            <Box as={Media} greaterThanOrEqual="lg" mx="5em" width="1px" bg="black" />
            <Box pt={responsive('2.875em', '1.875em')} pb={responsive(0, '2.875em')} maxWidth={responsive('32.5em', 'unset')} width={responsive('100%', '50%')} mx="auto">
              <BackgroundImage ratio={520.52 / 199.62} src={new_logo} />
            </Box>
          </Flex>
        </Box>
        <Box as={Media} lessThan="lg" width="3.75em">
          <BackgroundImage ratio={88.87 / 423.6} src={relab} />
        </Box>
        <Box as={Media} greaterThanOrEqual="lg" width="26.5625em" mb="2.625em" mx="auto">
          <BackgroundImage ratio={425 / 88.87} src={relab_desk} />
        </Box>
      </Flex>
      <Like />
      <Important />
      <Team />
      <Industry />
      <Box textAlign={'center'} mt={responsive('3.75em', '14em')} mb={responsive('4.875em', '5.875em')}>
        <Button.Outline
          leftIcon={<IoEnterOutline />}
          to="/portfolio/"
          px={responsive('1em', '4rem')}
          fontSize={responsive('1.25em', '2.5em')}
          py={responsive('0.5em', '0.42em')}
          trackerName={'about_collection'}
        >看各產業作品</Button.Outline>
      </Box>
    </Container>
    <SeeMore page="about" />
  </Box>
);

export default AboutPage;
