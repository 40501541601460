import React from 'react'

import Box from '../../../components/Box'
import Flex from '../../../components/Flex'
import Text from '../../../components/Text'
import BackgroundImage from '../../../components/BackgroundImage'
import { Media, responsive } from '../../../contexts/responsive'

import dna from './dna.svg'
import dna_desk from './dna_desk.svg'
import book from './book.svg'
import books from './books.svg'
import bulletin_board from './bulletin-board.svg'
import lorna from './lorna.svg'
import element from './element.svg'
import researcher from './researcher.svg'
import xx from './xx.svg'
import renddi from './renddi.svg'
import peja from './peja.svg'
import ming from './ming.svg'
import cindy from './cindy.svg'
import ym from './ym.svg'
import joyce from './joyce.svg'
import tina from './tina.svg'
import waiting from './waiting.svg'
import peter from './peter.svg'
import zoe from './zoe.svg'

const groups = [
  {
    src: xx,
    title: 'Co-founder\nConductor',
  },
  {
    src: renddi,
    title: 'Co-founder',
  },
  {
    src: ming,
    title: 'Customer\nSuccess\nManager',
  },
  // {
  //   src: peja,
  //   title: 'Engineer'
  // },
  {
    src: cindy,
    title: 'Project\nManager',
  },
  // {
  //   src: wenni,
  //   title: 'Graphic\nDesigner'
  // },
  {
    src: ym,
    title: 'Engineer',
  },
  {
    src: joyce,
    title: 'Graphic\nDesigner',
  },
  {
    src: tina,
    title: 'Project\nManager',
  },
  {
    src: lorna,
    title: 'Graphic\nDesigner',
  },
  {
    src: peter,
    title: 'Project\nManager',
  },
  {
    src: waiting,
    title: 'Graphic\nDesigner',
  },
  {
    src: zoe,
    title: 'Graphic\nDesigner',
  },
  // {
  //   src: expert,
  //   title: 'Experts'
  // },
]

const DNA = [
  {
    title: '實驗精神',
    eng: 'Experimental',
    text: '保持開放的態度，快速嚴謹地求證',
  },
  {
    title: '專業態度',
    eng: 'Professional',
    text: '重複做同一件事，一次做得比一次更好',
  },
  {
    title: '好玩有趣 ',
    eng: 'Compelling',
    text: '找到共鳴的關鍵，創造正向的記憶點',
  },
]

const Team = () => {
  return (
    <Box>
      <Box width={responsive('12.25em', '27em')}>
        <BackgroundImage ratio={433.61 / 92.32} src={element} />
      </Box>
      <Flex
        position={'relative'}
        flexDirection={responsive('column', 'row')}
        mt={responsive('0.875em', '1em')}
      >
        <Box flex={1} mt={responsive(0, '2em')} mr={responsive(0, '4.75em')}>
          <Text.Bold fontSize={responsive('0.875em', '1.75em')}>
            如果你也喜歡「實驗」，相信「專業」的價值，還認同「有趣」是最重要的事，那你或許適合加入我們！
          </Text.Bold>
        </Box>
        <Media greaterThanOrEqual="lg">
          <Box.Absolute width="67%" left="0" bottom="6%">
            <BackgroundImage src={bulletin_board} ratio={784.03 / 464.25} />
          </Box.Absolute>
        </Media>
        <Box.Relative
          bg="white"
          pt={responsive('2em', 0)}
          width={responsive('100%', '55.7%')}
        >
          <Box.Relative>
            <BackgroundImage ratio={651.63 / 85.45} src={book} />
            <Box.Absolute
              borderLeft={responsive('1.5px solid black', '3px solid black')}
              left="0"
              top="68.5%"
              bottom="0"
            />
          </Box.Relative>
          <Box.Relative
            px={responsive('2em', '4.375em')}
            pt={responsive('1.875em', '3.875em')}
            pb={responsive('3em', '6.5em')}
            borderBottom={responsive('6px solid black', '15px solid black')}
            borderLeft={responsive('1.5px solid black', '3px solid black')}
            borderRight={responsive('1.5px solid black', '3px solid black')}
          >
            <Media lessThan="lg">
              <Box>
                <BackgroundImage ratio={512.97 / 90.35} src={dna} />
              </Box>
            </Media>
            <Media greaterThanOrEqual="lg">
              <Box>
                <BackgroundImage ratio={516 / 99.76} src={dna_desk} />
              </Box>
            </Media>
            <Box ml="-0.25em">
              {DNA.map((d, i) => (
                <Box pt={responsive('1.5em', '3.125em')} key={i}>
                  <Flex alignItems={'center'} fontWeight={'bold'}>
                    <Text fontSize={responsive('1em', '2.25em')}>
                      【 {d.title} 】
                    </Text>
                    <Text fontSize={responsive('0.875em', '1.75em')}>
                      {d.eng}
                    </Text>
                  </Flex>
                  <Text ml="0.5em" fontSize={responsive('0.875em', '1.75em')}>
                    {d.text}
                  </Text>
                </Box>
              ))}
            </Box>
            <Box.Absolute
              width={responsive('5.125em', '11.5em')}
              right="0"
              bottom="0"
              transform="translateY(50%)"
            >
              <BackgroundImage ratio={191.09 / 209.76} src={researcher} />
            </Box.Absolute>
          </Box.Relative>
        </Box.Relative>
        <Media greaterThanOrEqual="lg">
          <Box.Absolute width="34%" left="12%" bottom="-1.2%">
            <BackgroundImage src={books} ratio={396.51 / 134.07} />
          </Box.Absolute>
        </Media>
      </Flex>
      <Box pt={responsive('4.375em', '12.375em')}>
        <Flex flexWrap={'wrap'} mx={responsive('-0.5em', '-1.125em')}>
          {groups.map((g, i) => (
            <Box
              mt={responsive(
                i > 1 ? '2em' : 0,
                i > 2 ? '3em' : 0,
                null,
                i > 3 ? '3em' : 0
              )}
              width={responsive('50%', 1 / 3, null, 1 / 4)}
              key={i}
            >
              <Box textAlign={'center'} mx={responsive('0.5em', '1.125em')}>
                <BackgroundImage src={g.src} ratio={1} />
                <Text
                  mt="0.25em"
                  whiteSpace="pre-wrap"
                  fontSize={responsive('1em', '2.25em')}
                  lineHeight="1.2"
                >
                  {g.title}
                </Text>
              </Box>
            </Box>
          ))}
        </Flex>
      </Box>
      <Box
        height={'1px'}
        bg="custom.borderGray"
        mt={responsive('2.5em', '8em')}
        mb={responsive('2.5em', '7em')}
      />
    </Box>
  )
}

export default Team
