import React from 'react';

import Box from '../../../components/Box';
import Flex from '../../../components/Flex';
import Text from '../../../components/Text';
import Image from '../../../components/Image';
import BackgroundImage from '../../../components/BackgroundImage';
import { responsive } from '../../../contexts/responsive';

import important from './important.svg';
import design from './design.svg';

const Important = () => {
  return (
    <Box>
      <Box width={responsive('16.875em', '37.5em')}><BackgroundImage src={important} ratio={605 / 95} /></Box>
      <Flex mt={responsive(0, '3.5em')} flexDirection={responsive('column', 'row')}>
        <Box flex={1} mr={responsive(0, '3.5em')}>
          <Text.Bold fontSize={responsive('0.875em', '1.875em')} mt="0.875rem">
            在快速變動的環境中，<br />
            協助品牌打造適合自己與顧客的溝通之道<br />
          </Text.Bold>
          <Text.Bold fontSize={responsive('0.875em', '1.875em')}>
            Build the shortcut to <br />
            your customer's heart
          </Text.Bold>
          <Text mt="0.25rem" fontSize={responsive('0.875em', '1.875em')} color="custom.textGray">
            先理性地分析，再佐以感性地訴求，每一段關係都能長長久久。<br />
            就算沒有資源為每位顧客量身打造溝通方式與服務，也可以分析出不同分眾，設計出最適合的幾款套餐，走進顧客心裡的捷徑，要靠品牌自己打造。
          </Text>
        </Box>
        <Box width={responsive('100%', '57.5%')} mt={responsive('1.5em', 0)}><BackgroundImage src={design} ratio={675 / 690} /></Box>
      </Flex>
      <Box height={'1px'} bg="custom.borderGray" mt={responsive('2.5em', '8.5em')} mb={responsive('2.5em', '7em')} />
    </Box>
  )
};

export default Important;
