import React from 'react';

import Box from '../../../components/Box';
import Flex from '../../../components/Flex';
import Text from '../../../components/Text';
import BackgroundImage from '../../../components/BackgroundImage';
import { Media, responsive } from '../../../contexts/responsive';

import bubble from './bubble.svg';
import partner from './partner.svg';
import bubble_desk from './bubble_desk.svg';

const Industry = () => {
  return (
    <Box id="industry">
      <Flex alignItems={responsive('auto', 'center')} flexDirection={responsive('column', 'row')} justifyContent={responsive('auto', 'space-between')}>
        <Box width={responsive('18em', '39.75em')}>
          <BackgroundImage src={partner} ratio={637.9 / 92.32} />
        </Box>
        <Text mt={responsive('1rem', 0)} textAlign={'right'} fontSize={responsive('0.875em', '1.875em')} color="custom.lightGray">(單位:個)</Text>
      </Flex>
      <Box pt={responsive("2.25em", '4em')}>
        <Media lessThan='lg'>
          <BackgroundImage src={bubble} ratio={680 / 925} />
        </Media>
        <Media greaterThanOrEqual='lg'>
          <BackgroundImage src={bubble_desk} ratio={1205 / 705} />
        </Media>
      </Box>
    </Box>
  );
};

export default Industry;
